<template>
    <div class="attestation__inner">
        <div class="attestation__head">
            <h2 class="attestation__title">Тестирование</h2>

<!--            <vue-easy-pie-chart-->
<!--                :size="75"-->
<!--                :scale-length="0"-->
<!--                :line-width="3"-->
<!--                :percent="percent"-->
<!--                bar-color="#fcc142"-->
<!--                track-color="#e9e9e9"-->
<!--                class="js-pie-chart-vue testing__timer"-->
<!--            >-->
<!--                <span class="testing__timer-body d-flex align-items-center justify-content-center">-->
<!--                    &lt;!&ndash;<span class="testing__time" style="font-size: 1rem">{{currentQuestion.timer}}</span>&ndash;&gt;-->
<!--                    <span-->
<!--                        class="testing__time"-->
<!--                        style="font-size: 0.8rem"-->
<!--                    >-->
<!--                        {{ totalTime.hours }} : {{ totalTime.minutes }} : {{ totalTime.seconds }}-->
<!--                    </span>-->
<!--                </span>-->
<!--            </vue-easy-pie-chart>-->
        </div>

        <div class="attestation__body">
            <form
                v-if="currentQuestion"
                class="attestation__question-form"
            >
                <div class="attestation__question">
                    {{currentQuestion.text}}
                </div>

                <a
                    v-if="currentQuestion.file"
                    @click="showPhotoModal"
                    href="javascript:void(0)"
                    class="js-question-image-link image-link"
                >
                    <img
                        :src="currentQuestion.file"
                        style="max-width: 540px"
                        class="attestation__image js-question-image mb-50 image-link"
                    />
                </a>

                <ul class="attestation__answer-list">
                    <li
                        v-for="answer in currentQuestion.answers"
                        :key="`answer-${answer.id}`"
                        class="attestation__answer-item"
                    >
                        <input
                            @click="clickRadio(answer.id)"
                            v-if="currentQuestion.multiple === '0'"
                            v-model="answer.checked"
                            :value="answer.id"
                            class="attestation__ctrl hidden-ctrl"
                            :id="`answer-${answer.id}`"
                            type="radio"
                            name="answer"
                        >

                        <input
                            v-else-if="currentQuestion.multiple === '1'"
                            v-model="answer.checked"
                            class="attestation__ctrl hidden-ctrl"
                            :id="`answer-${answer.id}`"
                            type="checkbox"
                        >

                        <div v-else-if="currentQuestion.multiple === '2'">
                            <label
                                :for="`answer-${answer.id}`"
                                class="attestation__label"
                            >
                                Введите вариант ответа
                            </label>
                            <textarea-autosize
                                v-model="answer.answered"
                                name="text" rows="3"
                                maxlength="1024"
                                class="form-control"
                                :min-height="175"
                                :max-height="350"
                                :id="`answer-${answer.id}`"
                            ></textarea-autosize>
                        </div>

                        <label
                            v-if="currentQuestion.multiple !== '2'"
                            :for="`answer-${answer.id}`"
                            class="attestation__ctrl-label"
                        >
                            <span class="attestation__ctrl-indicator"></span>
                            {{answer.text}}
                        </label>
                    </li>
                </ul>

                <div class="attestation__btn-wrap">
                    <button
                        type="button"
                        class="attestation__btn-stop btn"
                        @click="manualCompleteQuestion"
                    >
                        Завершить тестирование
                    </button>

                    <!-- атрибут disabled убирается после выбора ответов -->
                    <button
                        type="button"
                        class="attestation__btn-next btn v-btn--ready btn-rounded"
                        :disabled="answeredList && !answeredList.length"
                        @click="completeQuestion"
                    >
                        Ответить
                    </button>
                </div>
            </form>
        </div>

        <div class="attestation__footer">
            <div class="attestation__question-num">
                <span class="attestation__question-from">{{ step + 1 }}</span>
                <span class="hlt attestation__question-to">/{{ questionList.length }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import VueEasyPieChart from 'vue-easy-pie-chart'
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'
    import functions from "assets/js/functions";

    export default {
        name: "Question",
        components: {
            VueEasyPieChart,
        },
        props: ['onComplete', 'onSubmit'],
        data() {
            return {
                questionList: {},
                currentQuestion: {
                    timer: 30
                },
                answersList: [],
                step: 0,
                timer: null,
                timeInSeconds: 0,
                timeInSecondsFirst: 0,
                functions
            }
        },
        computed: {
            answeredList() {
                const {currentQuestion} = this;
                if (currentQuestion.multiple) {
                    if (currentQuestion.multiple === "2") {
                        return currentQuestion.answers.filter(answer => answer.answered).map(answer => answer.answered);
                    } else {
                        //Вернуть массив с ответами
                        return currentQuestion.answers.filter(answer => answer.checked).map(answer => answer.id);
                    }
                }
            },

            percent() {
                return this.timeInSeconds / this.timeInSecondsFirst * 100
            },

            totalTime() {
                return this.functions.msToTime(this.timeInSeconds*1000);
            }
        },
        methods: {
            clickRadio(answer_id) {
                this.currentQuestion.answers.forEach(item => {
                    if (item.id !== answer_id && item['checked']) {
                        delete item['checked']
                    }
                })
            },

            showPhotoModal() {
                const PhotoModal = () => import(`@/components/accounts/profile/PhotoModal`);
                this.$modal.show(PhotoModal, {
                    image: this.currentQuestion.file,
                    text: ''
                }, {
                    name: 'photo-modal',
                    classes: 'photo-modal'
                })
            },

            // Запуск следующего (или первого) вопроса
            startQuestion() {
                this.currentQuestion = {...this.questionList[this.step], answered: null};
            },

            // Завершение текущего вопроса
            completeQuestion() {
                const {currentQuestion} = this;
                // clearInterval(this.timer);

                this.answersList.push({
                    question_id: currentQuestion.id,
                    answers: this.answeredList
                });
                if (this.questionList.length === this.step+1) {
                    // Сравнение кол-ва вопросов и текущего вопроса для завершения теста
                    this.completeTesting();
                    return false;
                }
                this.step++;
                this.startQuestion();
            },

            // Завершение тестирования при нажатии на кнопку
            manualCompleteQuestion() {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Завершить тестирование?',
                    text: "Вы ответили не на все вопросы.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                }).then(value => {
                    if (value.value) {
                        let swal = this.$swal({
                            title: 'Завершение тестирования',
                            icon: 'info',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                        });
                        // выполнение
                        this.completeTesting();
                        swal.close();
                    }
                }, reason => {
                    // отказ
                });
            },

            // Завершение тестирования
            completeTesting() {
                if (this.timer) {
                    clearInterval(this.timer);
                }

                this.onSubmit(this.answersList);
            },

            // Запуск тестирования
            startTesting(data) {
                this.questionList = data;
                this.startQuestion();
                this.$store.dispatch('sidebar/setSidebarOpened', false);
            },
        },

         async created() {
            let request = {}
            let questions = {}

            window.onbeforeunload = () => "Вы уверены?";

            try {
                // document
                if (this.$route.query.type && this.$route.query.type === 'document') {
                    request = await session.get(`/api/v1/document/${this.$route.query.id}/question/`);
                    questions = request.data.questions;

                    window.onunload = () => session.post(`api/v1/document/${this.$route.query.id}/answer_question/`, this.answersList);
                // material
                } else {
                    request = await session.get('/api/v1/testing/question/');
                    questions = request.data.questions;

                    window.onunload = () => session.post('/api/v1/testing/answer_question/', this.answersList);
                }

                let data = request.data;

                this.timeInSeconds = data.minutes * 60 + data.seconds;
                //timeInSecondsFirst нужна для рассчёта процента от первоначального времени
                this.timeInSecondsFirst = this.timeInSeconds;

                if (this.$route.query.type !== 'document') {
                    this.timer = setInterval( () => {
                        /*
                        Если таймер будет у каждого вопроса
                        if (this.currentQuestion.timer === 0) {
                            this.completeQuestion();
                        }
                        this.currentQuestion.timer = this.currentQuestion.timer - 1;*/
                        if (this.timeInSeconds === 0) {
                            this.completeTesting();
                        }
                        this.timeInSeconds -= 1;
                    }, 1000)
                }

                this.startTesting(questions);
            } catch (e) {
                console.error(e);
            }
        },

        beforeDestroy() {
            window.onbeforeunload = null;
            window.onunload = null;
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .attestation {
        position: relative;
        &__label {
            color: #999999;
            font-weight: normal;
        }

        &__question {
            width: 100%;
        }

        &__image {
            @media (max-width: 768px) {
                width: 90vw;
            }
        }

        .vue-easy-pie-chart {
            @media (min-width: 768px) {
                top: 10px;
                right: 10px;
                position: absolute;
            }
        }
    }
</style>
